import { PropsWithChildren, ReactElement } from 'react';
import { Links, LiveReload, Meta, Scripts, ScrollRestoration } from "@remix-run/react";

export default function HtmlDocument(props: PropsWithChildren<unknown>): ReactElement {
    const { children } = props;

    return (
        <html lang="en" dir="ltr" className="h-full bg-gray-100">
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <Meta />
                <Links />
            </head>
            <body className="h-full">
                {children}
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
            </body>
        </html>
    );
}
