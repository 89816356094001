import { HtmlLinkDescriptor, LoaderFunctionArgs, json } from '@remix-run/node';
import {
  Outlet,
  isRouteErrorResponse,
  useLoaderData,
  useMatches,
  useRouteError } from
'@remix-run/react';
import i18next from 'i18next';
import { CustomFormats, IntlProvider } from 'react-intl';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import translation from 'zod-i18n-map/locales/de/zod.json';
import Content from './components/Content';
import HtmlDocument from './components/HtmlDocument';
import Layout from './components/Layout';
import messagesDe from './lang/de';
import styles from './tailwind.css';
import { db } from './utils/db.server';
import { getUserId } from './utils/session.server';
import CurrencyConversionProvider from './components/CurrencyConversionProvider';
import Currency from './types/Currency';
import { getCurrencyConversionMap } from './lib/convertCurrency.server';
import CurrencyMap from './utils/CurrencyMap';

i18next.init({
  lng: 'de',
  resources: {
    de: { zod: translation }
  }
});

z.setErrorMap(zodI18nMap);

export function meta() {
  return [{ title: 'MoneyView' }];
}

export function links(): HtmlLinkDescriptor[] {
  return [
  { rel: 'icon', type: 'image/png', href: '/moneyview.png' },
  { rel: 'stylesheet', href: styles },
  { rel: 'stylesheet', href: 'https://rsms.me/inter/inter.css' }];

}

export async function loader({ request }: LoaderFunctionArgs) {
  const kinds = await db.bookingKind.findMany({
    orderBy: { name: 'asc' }
  });

  const userId = await getUserId(request);
  let user = undefined;

  if (userId) {
    user = await db.member.findUnique({
      where: { id: userId }
    });
  }

  const currencyConversionMap = await getCurrencyConversionMap();

  return json({
    kinds,
    user,
    currencyConversionMap: Object.fromEntries(currencyConversionMap),
    defaultCurrency: currencyConversionMap.defaultCurrency
  });
}

export default function App() {
  const matches = useMatches();
  const { currencyConversionMap, defaultCurrency, kinds, user } =
  useLoaderData<typeof loader>();
  const currencyMap = new CurrencyMap(currencyConversionMap, defaultCurrency);

  const headline = matches.
  reverse().
  map((match) => (match.handle as any)?.headline).
  find((headline) => !!headline);

  const formats: CustomFormats = {
    date: {
      medium: {
        dateStyle: 'medium',
        timeStyle: 'short'
      }
    },
    number: {
      currency: {
        style: 'currency',
        currency: 'EUR'
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 1
      }
    }
  };

  const navigationEntries = kinds.map((kind) => ({
    name: kind.name,
    href: `/buchungen/${kind.slug}`
  }));

  return (
    <IntlProvider
      messages={messagesDe}
      locale="de"
      defaultLocale="de"
      formats={formats}
      defaultFormats={formats}>

            <CurrencyConversionProvider currencyMap={currencyMap}>
                <HtmlDocument>
                    <Layout
            headline={headline}
            navbarProps={{
              user: user ?? undefined,
              extraNaviagionEntries: navigationEntries
            }}>

                        <Outlet />
                    </Layout>
                </HtmlDocument>
            </CurrencyConversionProvider>
        </IntlProvider>);

}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    <HtmlDocument>
            <Layout headline={`${error.status} ${error.statusText}`}>
                <Content>
                    <p>Es ist ein Fehler aufgetreten.</p>
                    <pre>
                        <code>{JSON.stringify(error.data, null, 2)}</code>
                    </pre>
                </Content>
            </Layout>
        </HtmlDocument>;
  }

  return (
    <HtmlDocument>
            <Layout headline="Allgemeiner Fehler">
                <Content>
                    <p>Es ist ein Fehler aufgetreten.</p>
                    <pre>
                        <code>
                            {(error as Error)?.message ??
              JSON.stringify(error, null, 2)}
                        </code>
                    </pre>
                </Content>
            </Layout>
        </HtmlDocument>);

}