import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Member } from '@prisma/client';
import { NavLink } from '@remix-run/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import Container from './Container';
import Avatar from './ui/Avatar';
import Button from './ui/Button';
import UserMenu from './UserMenu';

interface NavigationEntry {
    name: string;
    href: string;
}

interface Props {
    user?: Pick<Member, 'avatar' | 'name'>;
    extraNaviagionEntries?: NavigationEntry[];
}

const navigation: NavigationEntry[] = [
    { name: 'Dashboard', href: '/' },
    { name: 'Konten', href: '/konten' },
];
<svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
>
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
    />
</svg>;

const userNavigation = [
    { name: 'Mitglieder', href: '/mitglieder' },
    { name: 'Buchungs-Typen', href: '/typen' },
    { name: 'Logout', href: '#' },
];

export default function Navbar(props: Props) {
    const { user, extraNaviagionEntries = [] } = props;

    return (
        <>
            <Disclosure as="nav" className="bg-gray-800 h-14 fixed w-full z-30">
                {({ open }) =>
                    user ? (
                        <>
                            <Container className="flex items-center justify-between py-2">
                                <div className="flex items-center">
                                    <img
                                        className="h-8 w-8"
                                        src="/moneyview.png"
                                        alt="Workflow"
                                    />
                                    <div className="hidden md:flex ml-10 items-baseline space-x-4 flex-wrap">
                                        {[
                                            ...navigation,
                                            ...extraNaviagionEntries,
                                        ].map((item) => (
                                            <NavLink
                                                key={item.name}
                                                to={item.href}
                                                className={({ isActive }) =>
                                                    clsx(
                                                        'px-3 py-2 rounded-md text-sm font-medium  hover:text-white',
                                                        isActive &&
                                                            'bg-gray-900 text-white',
                                                        !isActive &&
                                                            'text-gray-300 hover:bg-gray-700'
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                                <div className="hidden md:flex ml-4 items-center md:ml-6">
                                    <UserMenu user={user!}>
                                        {userNavigation.map((item) => (
                                            <NavLink
                                                key={item.name}
                                                to={item.href}
                                                className={({ isActive }) =>
                                                    clsx(
                                                        isActive
                                                            ? 'bg-gray-100'
                                                            : '',
                                                        'block px-4 py-2 text-sm text-gray-700'
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </UserMenu>
                                </div>
                                <div className="-mr-2 flex md:hidden">
                                    <Disclosure.Button as={Button}>
                                        <span className="sr-only">
                                            Open main menu
                                        </span>
                                        {open ? (
                                            <XMarkIcon
                                                className="block h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <Bars3Icon
                                                className="block h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </Container>

                            <Disclosure.Panel className="md:hidden relative bg-gray-800 z-20">
                                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                    {[
                                        ...navigation,
                                        ...extraNaviagionEntries,
                                    ].map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as={Fragment}
                                        >
                                            <NavLink
                                                key={item.name}
                                                to={item.href}
                                                className={({ isActive }) =>
                                                    clsx(
                                                        'block px-3 py-2 rounded-md text-base font-medium hover:text-white',
                                                        isActive &&
                                                            'bg-gray-900 text-white',
                                                        !isActive &&
                                                            'text-gray-300 hover:bg-gray-700'
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </NavLink>
                                        </Disclosure.Button>
                                    ))}
                                </div>
                                <div className="pt-4 pb-3 border-t border-gray-700">
                                    <div className="flex items-center px-5">
                                        <Avatar
                                            name={user.name}
                                            src={user.avatar ?? undefined}
                                        />
                                        <div className="ml-3">
                                            <div className="text-base font-medium leading-none text-white">
                                                {user.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 px-2 space-y-1">
                                        {userNavigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    ) : (
                        <></>
                    )
                }
            </Disclosure>
            <div className="h-14"></div>
        </>
    );
}
