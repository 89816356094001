export default {
    interval: `{type, select,
        daily {{value, plural,
            one {jeden Tag}
            other {alle # Tage}
        }}
        weekly {{value, plural,
            one {jede Woche}
            other {alle # Wochen}
        }}
        monthly {{value, plural,
            one {jeden Monat}
            other {alle # Monate}
        }}
        yearly {{value, plural,
            one {jedes Jahr}
            other {alle # Jahre}
        }}
        other {unbekannter Intervall}
    }`,
};