import { Menu, Transition } from '@headlessui/react';
import { Member } from '@prisma/client';
import { Children, Fragment, PropsWithChildren, ReactElement } from 'react';
import Avatar from './ui/Avatar';

interface Props {
    user: Pick<Member, 'avatar' | 'name'>;
}

const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
];

export default function UserMenu(
    props: PropsWithChildren<Props>
): ReactElement {
    const { children, user } = props;

    return (
        <Menu as="div" className="ml-3 relative">
            <div>
                <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open user menu</span>
                    <Avatar name={user.name} src={user.avatar ?? undefined} />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {Children.map(children, (child) => (
                        <Menu.Item>{child}</Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
