import { ComponentProps, PropsWithChildren, ReactElement } from 'react';
import Navbar from '~/components/Navbar';
import Container from './Container';

interface Props {
    headline?: string;
    navbarProps?: ComponentProps<typeof Navbar>;
}

export default function Layout(props: PropsWithChildren<Props>): ReactElement {
    const { children, headline, navbarProps = {} } = props;

    return (
        <div className="min-h-full">
            <Navbar {...navbarProps} />

            {headline ? (
                <header className="bg-white shadow">
                    <Container className="py-4">
                        <h1 className="text-3xl font-bold text-gray-900">{headline}</h1>
                    </Container>
                </header>
            ) : null}
            <Container as="main" className="py-4">
                {children}
            </Container>
        </div>
    );
}
