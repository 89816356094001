import clsx from 'clsx';
import { ElementType, PropsWithChildren, ReactElement } from 'react';

interface Props<TTag extends ElementType = 'div'> {
    className?: string;
    as?: TTag;
}

export default function Container<TTag extends ElementType = 'div'>(
    props: PropsWithChildren<Props<TTag>>,
): ReactElement {
    const { as: Component = 'div', className, children } = props;

    return <Component className={clsx('px-4 sm:px-6', className)}>{children}</Component>;
}
